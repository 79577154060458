const Menus = [
    {
        id: '0',
        name: '桥群管理',
        icon: 'iconfont icon-shouye',
        url: '/platform/bridge-group',
        type: 1,
        children: []
    }, {
        id: '1',
        name: '桥群环境',
        icon: 'iconfont icon-jiegoujiance',
        url: '/platform/environment',
        type: 0,
        children: [
            {
                id: '1-1',
                name: '风环境监测',
                icon: '',
                url: '/platform/environment/wind',
                type: 1,
            }, {
                id: '1-2',
                name: '温湿度监测',
                icon: '',
                url: '/platform/environment/temp',
                type: 1,
            }
        ]
    }, {
        id: '2',
        name: '结构监测',
        icon: 'iconfont icon-jiaotongjiance',
        url: '/platform/structure',
        type: 0,
        children: []
    }, {
        id: '3',
        name: '交通监测',
        icon: 'iconfont icon-shujufenxi',
        url: '/platform/traffic',
        type: 0,
        children: []
    }, {
        id: '4',
        name: '视频监测',
        icon: 'iconfont icon-qiaoliang',
        url: '/platform/video',
        type: 0,
        children: []
    }, {
        id: '5',
        name: '系统管理',
        icon: 'iconfont icon-shezhi1',
        url: '/platform/system',
        type: 0,
        children: [
            {
                id: '5-1',
                name: '用户管理',
                icon: '',
                url: '/platform/system/user',
                type: 1,
            }, {
                id: '5-2',
                name: '角色管理',
                icon: '',
                url: '/platform/system/role',
                type: 1,
            }, {
                id: '5-3',
                name: '菜单管理',
                icon: '',
                url: '/platform/system/menu',
                type: 1,
            }
        ]
    },
];

export default Menus
