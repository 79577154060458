<template>
  <el-submenu v-if="item.type < 1 && item.children && item.children.length" :index="item.url">
    <!-- 创建父级菜单 -->
    <template slot="title">
      <i v-if="item.icon" :class="item.icon"></i><span>{{ item.name }}</span>
    </template>
    <!-- 创建子菜单 -->
    <sidebar-item v-for="(subItem,i) in item.children" :key="navIndex+'-'+i" :navIndex="navIndex+'-'+i" :item="subItem"></sidebar-item>
  </el-submenu>
  <el-menu-item v-else-if="item.type < 2" :index="item.url">
    <i v-if="item.icon" :class="item.icon"></i><span>{{ item.name }}</span>
  </el-menu-item>
</template>

<script>
  export default {
    name: "SidebarItem",
    props: ["item", "navIndex"],
    data(){
      return{
        baseUrl:''
      }
    },
    created() {
      // console.log(this.item);
      // let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.baseUrl = localStorage.getItem('baseUrl')
      // let menus = ['结构配置','阈值管理','抓拍设置','检查指引管理','监测信息管理','配置管理','系统管理']
      // if (userInfo.name === 'testglj01'){
      //   if (menus.indexOf(this.item.name) !== -1){
      //     this.item.show = false
      //   }
      // }
      // if (this.item.name === '专项分析' && this.baseUrl === 'djksk'){
      //   this.item.show = true
      // } else if (this.item.name === '专项分析' && this.baseUrl !== 'djksk'){
      //   this.item.show = true
      // }
    }
  };
</script>
