<template>
  <div class="navbar">
    <div class="nav-left">
<!--      <el-button-->
<!--        size="small"-->
<!--        class="back-btn"-->
<!--        v-if="isBack"-->
<!--        @click="$router.go(-1)"-->
<!--      >-->
<!--        <span class="flex items-center"-->
<!--          ><i class="iconfont icon-fanhui mr-2 align-middle"></i>返回</span-->
<!--        ></el-button-->
<!--      >-->
<!--      <i-->
<!--        class="el-icon-s-home"-->
<!--        title="大屏页面"-->
<!--        -->
<!--        v-else-->
<!--      ></i>-->
      <div class="nav-left" style="cursor:pointer;" @click="handleBackScreen">前往大屏驾驶舱&gt;&gt;&gt;</div>
    </div>
    <div class="nav-right flex items-center">
      <div class="time">{{ datatime | dateFormat }}</div>
      <el-divider direction="vertical"></el-divider>
      <div class="user h-full flex items-center">
        <el-dropdown @command="handleCommand">
          <div class="avatar">
            <i class="el-icon-user-solid"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="e">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      datatime: new Date(),
    };
  },
  filters: {
    // 时间格式化
    dateFormat(value) {
      var addZero = function (value) {
        return value > 9 ? value : "0" + value;
      };
      return (
        value.getFullYear() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getDate() +
        "/" +
        "     " +
        value.getHours() +
        ":" +
        addZero(value.getMinutes()) +
        ":" +
        addZero(value.getSeconds())
      );
    },
  },
  computed: {
    ...mapState("dic", ["SUCCESS_CODE"]),
    isBack() {
      return this.$route.meta.showBack || false;
    },
  },
  created() {
    this.getInTime();
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "a":
          this.$router.push("/platform");
          break;
        case "b":
          this.$router.push("/single");
          break;
        case "e":
          this.logOut();
          break;
      }
    },
    // 退出登录
    logOut() {
      this.$http.get('/bridge/sys/user/logout')
      localStorage.removeItem('baseUrl');
      localStorage.removeItem('bridge-token');
      localStorage.removeItem('bridgeList');
      this.$router.push("/login");
    },
    // 获取实时时间
    getInTime() {
      let self = this;
      setInterval(function () {
        self.datatime = new Date();
      }, 1000);
    },
    // 返回大屏
    handleBackScreen() {
      // let type = localStorage.getItem("type") || "platform";
      // if (type == "single") {
      //   this.$router.push("/single");
      // } else {
      //   this.$router.push("/platform");
      // }
      if (this.$route.path.startsWith("/platform")) this.$router.push("/platform");
      if (this.$route.path.startsWith("/single")) this.$router.push("/single");
    },
  },
};
</script>


