import store from '@/store/index.js'
let dic = store.state.dic;

export default function Configs(that) {
    // 密码验证
    function validatePassword(rule, value, callback) {
        if (value) {
            if (that.handleData.newPassword === value) {
                callback()
            } else {
                callback('两次输入的密码不一致')
            }
        } else {
            callback('请确认密码')
        }

    }

    const formItems = [
        {
            label: "旧密码",
            span: 24,
            placeholder: '请输入旧密码',
            model: "oldPassword",
            password: true,
        },
        {
            label: "新密码",
            span: 24,
            placeholder: '请输入新密码',
            model: "newPassword",
            password: true,
        },
        {
            label: "密码确认",
            span: 24,
            placeholder: '请再次输入密码',
            model: "verifyPassword",
            password: true,
        },
    ]

    const formRules = {
        oldPassword: [{
            required: true,
            message: '请输入旧密码',
            trigger: "blur",
        }],
        newPassword: [{
            required: true,
            message: '请输入新密码',
            trigger: "blur",
        }],
        verifyPassword: [{
            required: true,
            trigger: "blur",
            validator: validatePassword
        }],
    }

    return {
        formItems,
        formRules,
    }
}
