<template>
  <div
    class="management-page"
    :class="{ hideSidebar: isCollapse, openSidebar: !isCollapse }"
  >
    <sidebar class="sidebar-container" :isCollapse="isCollapse" />
    <div class="main-container">
      <navbar />
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from "@/components/Layout";

export default {
  name: "Layout",
  data() {
    return {
      isCollapse: false,
    };
  },
  components: {
    Navbar,
    Sidebar,
    AppMain,
  },
  methods: {},
};
</script>

