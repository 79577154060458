<template>
  <div :class="{ 'has-logo': showLogo }" class="sidebar-wrapper">
    <div class="logo flex items-center justify-center">
      <!-- <img src="../../../assets/images/admin/logo.png" alt="" /> -->
      {{ title }}
      <el-dropdown @command="handleCommand">
        <i class="el-icon-arrow-down el-icon--right" style="color: white"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="value" v-for="(value, key) in bridgeNameList" :key="key">{{ value.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="sidebar-box">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu
          class="el-menu-vertical-side"
          :default-active="defActive"
          mode="vertical"
          menu-trigger="click"
          router
          unique-opened
          :collapse="isCollapse"
        >
          <sidebar-item
            v-for="(item, n) in menuList"
            :item="item"
            :navIndex="String(n)"
            :key="item.id"
          ></sidebar-item>
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import SidebarItem from "./SidebarItem";
import Menus from "@/assets/json/menuList";
// import MenuSingle from "@/assets/json/menuSingle";
import { mapState, mapActions } from "vuex";
import Configs from "./config";

export default {
  components: { SidebarItem },
  data() {
    let { formItems, formRules } = Configs(this);
    return {
      formItems,
      formRules,
      showLogo: true,
      userInfo: {},
      userRoleList: [],
      roleName: "",
      dialogFormVisible: false,
      menuList: [],
      handleData: {
        oldPassword: "",
        newPassword: "",
        verifyPassword: "",
      },
      title: "湖北省公路桥梁健康监测系统",
      bridgeNameList:{},
      bridgePrefix: ""
    };
  },
  props: {
    isCollapse: Boolean,
  },
  watch: {
    isCollapse(val) {
      // console.log(val)
    },
  },
  computed: {
    ...mapState("dic", ["SUCCESS_CODE"]),
    defActive() {
      return this.$route.path;
    },
  },
  created() {
    // let type = localStorage.getItem("type");
    this.bridgeNameList = this.$BRIDGE_NAME_LIST;
    this.bridgePrefix = localStorage.getItem("baseUrl");
    if(this.$route.path.startsWith("/platform")) this.menuList = Menus;
    if (this.$route.path.startsWith("/single")) {
      this.menuList = JSON.parse(localStorage.getItem('menuList'));
      for (let item of this.bridgeNameList){
        if (this.bridgePrefix === item.baseUrl){
          this.title = item.name + "健康监测系统";
          break
        }
      }
    }
  },
  mounted() {},
  methods: {
    handleOpen() {},
    handleClose() {},
    // 下拉菜单事件
    handleCommand(command) {
      if(command.baseUrl === this.bridgePrefix) return ;
      let self = this;
      this.$http.get('/bridge/sys/menu/tree/bri',{params:{id:command.id}}).then(res => {
        if (res.success){
          localStorage.setItem('menuList',JSON.stringify(res.data));
          localStorage.setItem("baseUrl", command.baseUrl);
          self.__proto__.__proto__.$MQURL = "ws://"+window.location.host+"/mqsocket/" + command.baseUrl + "/";
          this.$message({
            message: '切换中，请稍后...',
            duration: 1000,
            type: 'info',
            onClose: function () {
              self.reload();
            }
          });
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  beforeDestroy() {},
  inject: ['reload']
};
</script>
